<template>
  <div class="pt-4 pl-2 mt-5">
    <div class="h2 text-capitalize mb-0">
      {{ item.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardLabelNew",
  props: ["item"],
};
</script>
